
.submission{
  max-width: 800px;
  margin: 2em;
  border-radius: 25px;
  padding: 2em 2em;
  color: #333;
  background-color: #fff;
}



.submission textarea{
  min-height: 150px;
}

.submission .form-group{
  margin-top: 2em;
}

.submission fieldset{
  width: 100%;
}

form span{
  margin: .5em 1em;
}