
h1{
  font-size: 3.0em;
  font-weight: 200;
  letter-spacing: 2px;
  margin-bottom: 0;
}

h2{
  font-size: 2.5em;
  font-weight: 200;
  letter-spacing: 2px;
  margin-bottom: 0;
}

h3{
  font-size: 1.5em;
  font-weight: 200;
  letter-spacing: 1px;
}

header{
  max-width: 30em;
  padding: 4em 0em;
  text-align: center;
}

p{
  font-size: 1.0em;
  font-weight: 200;
  line-height: 1.5;
}

img{
  max-width: 100%;
}

.menu{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  background-color: #016FB9;
  top: 0;
  padding: 5px;
  color: #fff;

}

.menu-items{
  display: flex; 
  justify-content: center;
  align-items: center;
}

.menu-items a{
  padding: 0 1em;
  color: #fff;
  font-size: 1.2em;
  text-decoration: none;
  font-weight: 200;
}

.splash{
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  background-color: #016FB9;
  padding: 2em;
  color: #fff;
}


.logo{
  max-height: 400px;
  height: auto;
  width: auto;
  max-width: 100%;
}

.google-play {
  max-height: 100px;
  width: auto;
}

.content-wrapper{
  padding: 4em 2em 3em 2em;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.feature{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  align-content: center;
  padding: 3em;

}

.feature:nth-child(even){
  background-color: #016FB9;
  color: #fff;
}


.feature img{
  max-height: 400px;
  width: auto;
  box-shadow: 0px 0px 25px #333;
}
.intro{
  max-width: 45em;
  margin: 0 auto;
  padding: 5em;
  text-align: center;
}

.screenshots{
  display: flex;
  justify-content: space-evenly;
  align-content: center;
  flex-wrap: wrap;
  padding-bottom: 3em;
}
.screenshots img{
  height: 300px;
  width: auto;
  margin: 1em;
  box-shadow: 0px 0px 25px #333;
}

.download{
  display: flex;
  flex-direction: column;
  background-color: #016FB9;
  color: #fff;
  align-items: center;
  justify-content: space-evenly;
}

.privacy{
  max-width: 40em;
  margin: 0 auto;
}

.align-center{
  text-align: center;
}
